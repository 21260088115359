/**
 * Utils Module
 * PLEASE put here all the functions that do not belong in a particular class
 */

export function getParameterByName(name) {
    // Retrieves GET parameter value by its name
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    // Returns that value
    return params.get(name);
}

export function setCookie(name, value, days) {
    // Sets a cookie named <name> to <value>, sets expiration after <days>
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookieValue(name) {
    // Add the '=' symbol to the name to search for an exact match in the cookie string
    let nameEQ = name + "=";
    // Split the cookie string into an array of "key=value" pairs
    let ca = document.cookie.split(';');

    // Loop through the array of cookies
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // Trim whitespace from the cookie string
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(nameEQ) === 0) {
            // Return the cookie value, slicing from the end of the name to the end of the "key=value"
            return c.substring(nameEQ.length, c.length);
        }
    }
    // Return null if the cookie was not found
    return null;
}